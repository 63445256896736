import React from 'react';
import {useGoVehisService} from "../../utils/axios";
import DeleteDialog from "./DeleteDialog";


const DeleteMultipleOffersDialog = ({selectedOffers, onDeleted}) => {
  const goVehisService = useGoVehisService();


  const handleDeleteVehicleOffer = () => {
    return goVehisService.delete('admin/vehicleOffer?selected=' + [selectedOffers])
      .finally(() => onDeleted());
  }

  return (
    <DeleteDialog
      title="Potwierdzenie usunięcia wielu ofert pojazdów"
      content={"Czy na pewno usunąć nastęująca liczę ofert pojazdów: " + selectedOffers.length + "?"}
      onDeleteClick={handleDeleteVehicleOffer}
      />

  );
}

export default DeleteMultipleOffersDialog;
