import React from 'react';
import {useGoVehisService} from "../../utils/axios";
import DeleteDialog from "./DeleteDialog";


export default function DeleteSingleOfferDialog({data, onDeleted}) {
  const goVehisService = useGoVehisService();

  const handleDeleteVehicleOffer = () => {
    return goVehisService.delete('/admin/vehicleOffer/'+data.vehicleId)
      .finally(() => onDeleted());
  }

  return (
    <DeleteDialog
      title="Potwierdzenie usunięcia oferty pojazdu"
      content={"Czy na pewno usunąć ofertę samochodu " + data.brandName + " " + data.versionName + "?"}
      onDeleteClick={handleDeleteVehicleOffer}
    />

  );
}
