import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import '../../style.css';
import VehicleOfferDialog from "./VehicleOfferDialog";
import {useGoVehisService} from "../../utils/axios";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import vehicleOfferDialogWithLoadedData from "./VehicleOfferDialogWithLoadedData";
import VehicleOfferDialogWithLoadedData from "./VehicleOfferDialogWithLoadedData";
import {ControlPointDuplicate} from "@material-ui/icons";


function DuplicateVehicleOffer({data, onSaved}) {

  const [isOpen, setIsOpen] = React.useState(false);

  const goVehisService = useGoVehisService();

  const submitHandler = (formData) => {

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }

    return  goVehisService.post('/admin/vehicleOffer', formData, config)
      .then(res => {
        onSaved();
      });
  }

  return (
    <div>
      <Tooltip title="Utwórz kopię" placement="left-start">
        <ControlPointDuplicate onClick={() => setIsOpen(true)} />
      </Tooltip>
      <VehicleOfferDialogWithLoadedData
        data={data}
        onSubmit={submitHandler}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Nowa oferta"
      />
    </div>
  );
}

export default DuplicateVehicleOffer;
