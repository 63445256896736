import {Box} from "@material-ui/core";
import {useForm} from "react-hook-form";
import React, {useContext, useState} from "react";
import AlertDialog from "../AlertDialog";
import {useGoVehisService} from "../../../utils/axios";
import Button from "@material-ui/core/Button";
import {AuthContext} from "../../../context/auth-context";
import {convertFromRaw} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import SpecificationTabPanel from "./SpecificationTabPanel";
import GeneralInformationTabPanel from "./GeneralInformationTabPanel";
import TechnicalDataTabPanel from "./TechnicalDataTabPanel";
import ImagesTabPanel from "./ImagesTabPanel";
import RentalOfferTabPanel from "./RentalOfferTabPanel";

const VehicleOfferForm = ({data, tabValue, onSubmit, onCancel, onRentalShow}) => {

  const [mainFilesDialogAlert, setMainFilesDialogAlert] = useState(false);
  const [mainFiles, setMainFiles] = useState(null);
  const [additionalImages, setAdditionalImages] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasRentalOfferErrors, setHasRentalOfferErrors] = useState(false);

  const authContext = useContext(AuthContext);

  const form = useForm();

  const {handleSubmit, watch, getValues} = form;
  const watchIsNewVehicle = watch("condition") === "NEW";

  const goVehisService = useGoVehisService();

  const tabProps = {
    data: {
      ...data,
      isRental: getValues("calculationType") === "RENTAL"
    },
    form: form,
    tabValue: tabValue
  };

  const loadImage = url => goVehisService({
    url: "/admin/imageFile?url=" + url.replace('&', '!ampersand!').replace('?', '!questionmark!'),
    method: 'GET',
    responseType: 'blob',
  });

  const handleSimilarImageUrlSearch = (data) => {
    let url = '';
    goVehisService.post('/admin/missingimage', {
      brandName: data.brandName,
      modelName: data.modelName,
      bodyType: data.bodyType
    })
      .then(response => {
        url = response.data;

        if (url === null) {
          setMainFilesDialogAlert(true);
        } else {

          loadImage(url).then((res) => {
            if (res.data === null) {
              setMainFilesDialogAlert(true);
            } else {
              setMainFiles(res.data);
              handlePreparePayloadToSend(data, res.data);
            }
          })
        }

      })
  }

  const handlePreparePayloadToSend = (data, mainFile) => {
    const formData = new FormData();
    formData.append("mainImage", mainFile);

    for (let i = 0; i < additionalImages.length; i++) {
      formData.append("additionalImages", additionalImages[i]);
    }

    for (let i = 0; i < attachments.length; i++) {
      formData.append("attachments", attachments[i].file);
      formData.append("attachmentNames[" + i + "]", attachments[i].name ? attachments[i].name : attachments[i].filename);
    }

    formData.append("formData", JSON.stringify(data));

    onSubmit(formData);
  }

  function equipmentDataToArray(equipment) {
    let cleanedEquipment = equipment.trim();
    return cleanedEquipment ? cleanedEquipment.split(",") : [];
  }

  function validateRentalOffer(rentalOffer) {
    let isValidInstallment = true;

    if(rentalOffer === null || rentalOffer === undefined){
      return true;
    }

    for (const installment of rentalOffer.installments) {

      if (installment.value === "" || installment.value === undefined) {
        isValidInstallment = false;
        break;
      }

      if (Number.isNaN(Number.parseFloat(installment.value))) {
        isValidInstallment = false;
        break;
      }
    }

    if (!isValidInstallment) {
      setHasRentalOfferErrors(true);
    }

    return isValidInstallment;
  }

  const saveData = (data) => {

    let isValid = validateRentalOffer(data.rentalOffer);

    if (isValid) {
      data = {
        ...data,
        hasReturnGuarantee: watchIsNewVehicle ? data.hasReturnGuarantee : false,
        basicPriceNet: watchIsNewVehicle ? data.basicPriceNet : data.minimumSellPriceNet,
        versionName: data.modelName + " " + data.versionName,
        standardEquipment: equipmentDataToArray(data.standardEquipment),
        additionalEquipment: equipmentDataToArray(data.additionalEquipment),
        color: data.color.trim(),
        ownerCode: authContext.user.code,
        description: stateToHTML(convertFromRaw(JSON.parse(data.description))).replace("<p><br></p>", "")
      }

      if (mainFiles) {
        handlePreparePayloadToSend(data, mainFiles);
      } else {
        handleSimilarImageUrlSearch(data);
      }
    }
  }

  const errorHandler = (errors) => {
    setHasErrors(errors && Object.keys(errors).length > 0)
  }


  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(saveData, errorHandler)}>

        <GeneralInformationTabPanel {...tabProps} onRentalShow={onRentalShow}/>

        {tabProps.data.isRental && <RentalOfferTabPanel {...tabProps} />}

        <TechnicalDataTabPanel {...tabProps} />

        <SpecificationTabPanel
          {...tabProps}
          attachments={attachments}
          onSetAttachments={(attachments) => setAttachments(attachments)}
        />

        <ImagesTabPanel
          {...tabProps}
          onSetMainImage={(files) => setMainFiles(files)}
          onSetAdditionalImages={(files) => setAdditionalImages(files)}
        />

        <Box textAlign="right" mb={3}>
          <Button onClick={onCancel} variant="contained" style={{marginRight: 10}}>
            Anuluj
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Zapisz
          </Button>
        </Box>
      </form>
      <AlertDialog
        title="Nie dodano zdjęcia głównego!"
        content={<><p>Wybór zdjęcia głównego jest wymagany aby dodać ofertę pojazdu!</p><p>System nie znalazł
          odpowiedniego zdjęcia do wprowadzanego pojazdu!</p></>}
        isOpen={mainFilesDialogAlert}
        onClose={() => setMainFilesDialogAlert(false)}
      />
      <AlertDialog
        title="Nie wypełniono poprawnie wymaganych pól!"
        content="Proszę poprawnie uzupełnić pola na fomularzu! Proszę sprawdzić wszystkie zakładki."
        isOpen={hasErrors}
        onClose={() => setHasErrors(false)}
      />
      <AlertDialog
        title="Nie wypełniono poprawnie wszystkich rat wynajmu!"
        content="Proszę poprawnie uzupełnić pola dla rat wynajmu wartościami liczbowymi!"
        isOpen={hasRentalOfferErrors}
        onClose={() => setHasRentalOfferErrors(false)}
      />
    </>
  );
}

export default VehicleOfferForm;
