import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from "@material-ui/icons/Delete";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import {useGoVehisService} from "../../utils/axios";
import {isPromise} from "formik";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialogTitle: {
    textAlign: "center"
  }
}));

const DeleteDialog = ({title, content, onDeleteClick}) => {
  const [open, setOpen] = React.useState(false);
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const classes = useStyles();


  const handleLoaderClose = () => {
    setLoaderOpen(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteVehicleOffer = () => {
    setLoaderOpen(true);
    let promise = onDeleteClick();

    if(isPromise(promise)){
      promise.finally(() => {
        setLoaderOpen(false);
        setOpen(false);
      })
    }
  }

  return (
    <>
      <Tooltip title="Usuń" placement="left-start">
        <DeleteIcon onClick={handleClickOpen} />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Backdrop className={classes.backdrop} open={loaderOpen} onClick={handleLoaderClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Typography variant="body1" gutterBottom>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleDeleteVehicleOffer} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


export default DeleteDialog;
