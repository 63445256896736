import {Navigate} from 'react-router';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import OfferList from 'src/pages/OfferList';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import React from "react";
import Logout from "./components/account/Logout";

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'offers', element: <OfferList /> },
      { path: '/', element: <Navigate to="/app/offers"  /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'logout',
    element: <Logout />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/offers" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
