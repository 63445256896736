import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import '../../style.css';
import VehicleOfferDialog from "./VehicleOfferDialog";
import {useGoVehisService} from "../../utils/axios";


function AddVehicleOffer({onSaved}) {

  const [isOpen, setIsOpen] = React.useState(false);

  const [dataForm] = useState({
    vehicleType: "",
    brandName: "",
    modelName: "",
    vehicleName: "",
    manufactureYear: "",
    segment: "",
    bodyType: "",
    gearboxType: "",
    fuelType: "",
    driveType: "",
    engineCapacity: "",
    enginePowerHp: "",
    minimumSellPriceNet: "",
    minimumSellPriceGross: "",
    basicPriceNet: "",
    basicPriceGross: "",
    customerType: [],
    availabilityType: "",
    isSpecial: false,
    isActive: true,
    isSearchable: true,
    color: "",
    city: "",
    numberOfDoors: "",
    numberOfSeats: "",
    standardEquipment: "",
    additionalEquipment: "",
    brandCode: "",
    modelCode: "",
    versionCode: "",
    isRegistered: false,
    registeredDate: null,
    condition: "",
    hasReturnGuarantee: false,
    salesDocument: "",
    description: "",
    isPreviewPhoto: true,
    supplierNip: "",
    internalDescription: "",
    calculationType: "LEASING",
    rentalOffer: {
      selectedOptions: [],
      company: {
        code: ""
      },
      installments: []
    }
  });


  const [testDataForm] = useState({
    vehicleType: "OSOBOWE",
    brandName: "",
    modelName: "",
    versionName: "Test",
    manufactureYear: "2020",
    segment: "",
    bodyType: "",
    gearboxType: "Manualna",
    fuelType: "Benzyna",
    driveType: "Na wszystkie koła",
    engineCapacity: "2100",
    enginePowerHp: "125",
    minimumSellPriceNet: "100000",
    minimumSellPriceGross: "",
    basicPriceNet: "150000",
    basicPriceGross: "",
    customerType: ['COMPANY'],
    availabilityType: "IN_STOCK",
    isSpecial: false,
    isActive: true,
    isSearchable: true,
    color: "biały",
    city: "Warszawa",
    numberOfDoors: "5",
    numberOfSeats: "7",
    standardEquipment: "ABS,elektryczne szyby",
    additionalEquipment: "czujnik cofania",
    brandCode: "",
    modelCode: "",
    versionCode: "",
    isRegistered: false,
    condition: "NEW",
    hasReturnGuarantee: false,
    salesDocument: "",
    mileage: 15000,
    vin: "WASF3234235",
    description: "OPis zwew",
    isPreviewPhoto: true,
    supplierNip: "7418529632",
    internalDescription: "Opis wewn.",
    calculationType: "LEASING",
    rentalOffer: {
      selectedOptions: [],
      company: {
        code: ""
      },
      installments: []
    }
  });

  const goVehisService = useGoVehisService();


  const submitHandler = (formData) => {

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }

    return  goVehisService.post('/admin/vehicleOffer', formData, config)
      .then(res => {
        onSaved();
      });
  }


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={() => setIsOpen(true)}>
        Dodaj ofertę
      </Button>
      <VehicleOfferDialog
        data={dataForm}
        isOpen={isOpen}
        onSubmit={submitHandler}
        onClose={() => setIsOpen(false)}
        title="Nowa oferta"
      />
    </div>
  );
}

export default AddVehicleOffer;
