import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import React, {useState} from "react";
import {useGoVehisService} from "../../../utils/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const ReserveOffer = ({offer, onChange}) => {

  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);

  const goVehisService = useGoVehisService();

  const handleOpenMessageDialog = () => setOpenMessageDialog(true);
  const handleCloseMessageDialog = () => setOpenMessageDialog(false);

  const handleReserveButtonClick = () => {
    setBackdropOpen(true);
    goVehisService.post("/admin/reservation", {vehicleId: offer.vehicleId, message: message})
      .finally(() => {
        setOpenMessageDialog(false);
        onChange();
        setBackdropOpen(false);
      });
  }

  return (
    <>
      <Button size="small" variant="contained" color="primary" onClick={handleOpenMessageDialog}>
        Rezerwuj
      </Button>
      <Backdrop open={backdropOpen} >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Rezerwacja samochodu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Potwierdź chęć rezerwacji samochodu u opiekuna oferty. Możesz też napisać do niego dodatkowe uwagi lub pytania.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reservationMessage"
            label="Wiadomość"
            type="text"
            variant="outlined"
            value={message}
            onChange={ event => setMessage(event.target.value)}
            fullWidth
            multiline
            rows={5}
          />
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleCloseMessageDialog}>
            Anuluj
          </Button>
          <Button color="primary" onClick={handleReserveButtonClick}>
            Rezerwuj
          </Button>
        </DialogActions>
      </Dialog>
    </>);
}

export default ReserveOffer;
