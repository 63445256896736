import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useState} from "react";
import {useGoVehisService} from "../../../utils/axios";

const CancelReservation = ({offer, onChange}) => {

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const goVehisService = useGoVehisService();

  const handleCancelReservationButtonClick = () => {
    goVehisService.delete("/admin/reservation/" + offer.vehicleId)
      .finally(() => {
        setOpenDialog(false);
        onChange();
      });
  }


  return (
    <>
      <Button size="small" variant="contained" color="primary" onClick={handleOpenDialog}>
        Anuluj rezerwację
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Anulowanie rezerwacji</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Po anulowaniu rezerwacji samochód będzie widoczny jako dostępny.<br/>
            Czy na pewno anulować rezerwację?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default">
            Nie
          </Button>
          <Button onClick={handleCancelReservationButtonClick} color="primary" autoFocus>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </>);
}

export default CancelReservation;
