import React from 'react';
import '../../style.css';
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import VehicleOfferDialogWithLoadedData from "./VehicleOfferDialogWithLoadedData";
import {useGoVehisService} from "../../utils/axios";


function EditVehicleOfferButton({data, onSaved}) {

  const [isOpen, setIsOpen] = React.useState(false);

  const goVehisService = useGoVehisService();

  const submitHandler = (formData) => {

    formData.set("formData", JSON.stringify({
      ...JSON.parse(formData.get("formData")),
      id: data.id,
      vehicleId: data.vehicleId
    }));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }

    return  goVehisService.put('/admin/vehicleOffer', formData, config)
      .then(res => {
        onSaved();
      });
  }

  return (
    <div>
      <Tooltip title="Edytuj" placement="left-start">
        <EditIcon onClick={() => setIsOpen(true)} />
      </Tooltip>
      <VehicleOfferDialogWithLoadedData
        data={data}
        onSubmit={submitHandler}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Edycja oferty"
      />
    </div>
  );
}

export default EditVehicleOfferButton;
