import React, {useContext} from "react";
import {SearchOptionsContext} from "../../../context/search-options-context";
import theme from "../../../theme";
import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import {Controller} from "react-hook-form";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DropzoneArea} from "material-ui-dropzone";
import TabPanel from "../../common/TabPanel";

const ImagesTabPanel = ({data, tabValue, form, onSetMainImage, onSetAdditionalImages}) =>{


  const {control} = form;

  return (
    <TabPanel value={tabValue} index={4} dir={theme.direction}>

      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="isPreviewPhoto"
          control={control}
          defaultValue={data.isPreviewPhoto}
          render={({field}) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  color="primary"
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              }
              label="Czy zdjęcie poglądowe?"
            />

          )}
        />
      </Grid>

      <DialogTitle id="form-dialog-title">Zdjęcie główne</DialogTitle>
      <DropzoneArea
        acceptedFiles={['image/*']}
        onChange={(file) => {
          onSetMainImage(file[0]);
        }}
        showFileNames={false}
        initialFiles={[data.mainImage]}
        dropzoneText="Kliknij lub przeciągnij zdjęcia"
        showAlerts={true}
        filesLimit={1}
        maxFileSize={5000000}
      />
      <DialogTitle id="form-dialog-title">Zdjęcia dodatkowe (maksymalnie 15 szt.)</DialogTitle>
      <DropzoneArea
        acceptedFiles={['image/*']}
        onChange={(file) => {
          onSetAdditionalImages(file);
        }}
        showFileNames={false}
        initialFiles={data.additionalImages}
        dropzoneText="Kliknij lub przeciągnij zdjęcia"
        showAlerts={true}
        filesLimit={15}
        maxFileSize={5000000}
      />
    </TabPanel>
  );
}

export default ImagesTabPanel;
