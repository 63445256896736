import React, {useEffect, useState} from "react";
import {useGoVehisService} from "../../utils/axios";
import VehicleOfferDialog from "./VehicleOfferDialog";

const VehicleOfferDialogWithLoadedData = ({data, isOpen, onClose, onSubmit, title}) => {

  const [open, setOpen] = useState(false);
  const [loadedData, setLoadedData] = useState(data);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [rentalOffer, setRentalOffer] = useState(null);


  const goVehisService = useGoVehisService();

  const loadImage = url => {

    if(url !== null){
      url = url.replace('&', '!ampersand!').replace('?', '!questionmark!');
    }

    return goVehisService({
      //string replacements below are important to avoid treating
      //parts of url query param as different query params,
      //thus causing bugs
      url: "/admin/imageFile?url=" + url,
      method: 'GET',
      responseType: 'blob',
    });
  }

  const loadRentalOffer = () => goVehisService.get("/admin/rental-offer/" + data.vehicleId).then( response => setRentalOffer(response.data));



  useEffect(() => {
    setLoadedData({
      ...data,
      versionName: data.versionName.replace(data.modelName, '').trim(),
      standardEquipment: data.standardEquipment.join(),
      additionalEquipment: data.additionalEquipment.join(),
      mainImage: mainImage,
      additionalImages: additionalImages,
      rentalOffer: rentalOffer
    })
  }, [mainImage, additionalImages, rentalOffer]);

  const createImage = (blob, fileName) => new File([blob], fileName, {type: "image/jpg"})

  useEffect(() => {
    if (isOpen) {
      let mainImagePromise = loadImage(data.mainImage).then(response => setMainImage(createImage(response.data, "mainImage")));
      let allPromises = [mainImagePromise];
      setAdditionalImages([]);

      let index = 1;
      for (const imageUrl of data.additionalImages) {
        allPromises.push(loadImage(imageUrl).then(response =>
          setAdditionalImages(prevValue => [...prevValue, createImage(response.data, "additionalImage" + index)])))
        index++;
      }

      if(data.rental) {
        allPromises.push(loadRentalOffer());
      } else {
        setRentalOffer({
            selectedOptions: [],
            company: {
              code: ""
            },
            installments: []
          });
      }

      Promise.all(allPromises).finally(() => {
        setOpen(true)
      });
    }
  }, [isOpen]);

  const handleCloseDialog = () => {
    setOpen(false);
    onClose();
  }

  return (
    <VehicleOfferDialog
      data={loadedData}
      isOpen={open}
      onClose={handleCloseDialog}
      onSubmit={onSubmit}
      title={title}
    />
  );
}

export default VehicleOfferDialogWithLoadedData;
