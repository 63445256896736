import React, {useContext} from "react";
import {Typography} from "@material-ui/core";
import RentalInstallmentGrid from "./RentalInstallmentGrid";
import {SearchOptionsContext} from "../../../../context/search-options-context";

const RentalInstallments = ({company, installments, customerTypes, onChange}) => {

  const searchOptions = useContext(SearchOptionsContext);

  const CUSTOMER_TYPE = {
    COMPANY: "COMPANY",
    PERSON: "PERSON",
  }

  const companyInitialFees = [0, 10];
  const personInitialFees = [0];


  function priceTypeLabel(customerType) {
    return customerType === CUSTOMER_TYPE.COMPANY ? "netto" : "brutto";
  }

  const createInstallmentsGridsFor = (customerType, initialFees) => {
    let installmentsGrids = null;
    if (customerTypes.includes(customerType)) {
      installmentsGrids = initialFees.map(initialFee => (
        <div key={initialFee} >
          <Typography variant="h6" gutterBottom>Raty {priceTypeLabel(customerType)} dla opłaty wstępnej - {initialFee} %</Typography>
          <RentalInstallmentGrid
            company={company}
            installments={installments}
            initialFeePercentage={initialFee}
            customerType={customerType}
            onChange={onChange}
          />
        </div>
      ))

      installmentsGrids = (<>
        <Typography variant="h4" gutterBottom style={{marginTop: "25px"}}>Oferta dla {searchOptions.customerTypeName[customerType]}</Typography>
        {installmentsGrids}
      </>);
    }

    return installmentsGrids;
  }

  return (
    <>
      {createInstallmentsGridsFor(CUSTOMER_TYPE.COMPANY, companyInitialFees)}
      {createInstallmentsGridsFor(CUSTOMER_TYPE.PERSON, personInitialFees)}
    </>
  );
}

export default RentalInstallments;
