import {useContext} from "react";
import {SearchOptionsContext} from "../../../context/search-options-context";
import {AuthContext} from "../../../context/auth-context";
import ReserveOffer from "../components/ReserveOffer";
import CancelReservation from "../components/CancelReservation";

const OfferState = ({offer, onChange}) => {

  const searchOptions = useContext(SearchOptionsContext);
  const authContext = useContext(AuthContext);

  const isUserOwner =  authContext.user.code === offer.ownerCode;
  const isReserved =  offer.state === "RESERVED";
  const showReservationCancel = isUserOwner && isReserved;

  return (
    <>
      {searchOptions.offerState[offer.state]}
      <br/>
      { !isUserOwner && <ReserveOffer offer={offer} onChange={onChange}/>}
      { showReservationCancel && <CancelReservation offer={offer} onChange={onChange}/>}
    </>);
}


export default OfferState;
