import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import 'src/mixins/chartjs';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import {useRoutes} from "react-router";
import {ThemeProvider} from "@material-ui/core";
import routes from './routes';
import AuthContextProvider from "./context/auth-context";

function App() {
  const routing = useRoutes(routes);


  return (
    <React.Fragment>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles/>
          {routing}
        </ThemeProvider>
      </AuthContextProvider>
    </React.Fragment>
  )
}

export default App;
