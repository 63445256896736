import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useEffect, useState} from "react";
import {DropzoneDialog} from "material-ui-dropzone";
import {
  Box,
  Button, CircularProgress, Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {useGoVehisService} from "../../../utils/axios";

const Attachments = ({attachments, onChange , vehicleId}) => {

  const [dropzoneDialogOpen, setDropzoneDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const goVehisService = useGoVehisService();

  const hasAttachments = attachments.length > 0;

  const loadAttachment = url => goVehisService({
    url: "/admin/attachmentFile?url=" + url,
    method: 'GET',
    responseType: 'blob',
  });

  useEffect(() => {
    if(vehicleId) {
      setIsLoading(true);
      goVehisService.get("/admin/vehicleOffer/" + vehicleId + "/attachments")
        .then(response => {
          let loadedAttachments = [...response.data];

          if(loadedAttachments) {
            for (const attachment of loadedAttachments) {
              loadAttachment(attachment.url)
                .then((response => attachment.file = new File([response.data], attachment.filename)))
            }

            onChange(loadedAttachments);
          }
        })
        .finally( () => setIsLoading(false));
    }
  },[vehicleId]);

  const handleChangedName = (event, index) => {
    let changedAttachments = [...attachments];
    changedAttachments[index].name = event.target.value;

    onChange(changedAttachments);
  }

  const handleDeleteClick = index => {
    let changedAttachments = [...attachments];
    changedAttachments.splice(index, 1);

    onChange(changedAttachments);
  }

  const handleAddFile = files => {
    let changedAttachments = [...attachments];

    for (const file of files) {
      changedAttachments.push({
        name: "",
        filename: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    }



    onChange(changedAttachments);
    setDropzoneDialogOpen(false);
  }

  return isLoading ? <CircularProgress /> :
    <>
      <Box mb={2} textAlign="right">
        <Button variant="contained" color="primary" onClick={() => setDropzoneDialogOpen(true)}>
          Dodaj załącznik
        </Button>
      </Box>

      <DropzoneDialog
        acceptedFiles={['.pdf', '.doc', '.docx', '.odt']}
        cancelButtonText={"Anuluj"}
        submitButtonText={"Dodaj"}
        dialogTitle="Dodaj pliki załączników"
        dropzoneText="Przyciągnij i upuść plik lub kliknij"
        maxFileSize={5000000}
        filesLimit={10}
        open={dropzoneDialogOpen}
        onClose={() => setDropzoneDialogOpen(false)}
        onSave={handleAddFile}
        showPreviews={true}
      />

      {hasAttachments &&
      <TableContainer component={Paper}>
        <Table size="small">
          <colgroup>
            <col style={{width: '70%'}}/>
            <col style={{width: '20%'}}/>
            <col style={{width: '10%'}}/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa załącznika</TableCell>
              <TableCell align="center">Plik</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments.map((attachment, index) => (
              <TableRow key={attachment.filename}>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder={"Wprowadź nazwę dla pliku " + attachment.filename}
                    value={attachment.name}
                    onChange={(event) => handleChangedName(event, index)}
                  />
                </TableCell>
                <TableCell align="center">
                  {attachment.url &&
                  <Link href={attachment.url} target="_blank">Zobacz
                    <OpenInNewIcon style={{fontSize: 15}}/>
                  </Link>
                  }
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Usuń" placement="left-start">
                    <DeleteIcon onClick={() => handleDeleteClick(index)}/>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  ;
}

export default Attachments;
