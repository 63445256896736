import {Helmet} from 'react-helmet';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Container, TextField, Typography} from '@material-ui/core';
import {useNavigate} from "react-router";
import Logo from "../components/Logo";
import theme from "../theme";
import {styled} from "@material-ui/styles";
import {goVehisService, useGoVehisService} from "../utils/axios";
import React, {useContext, useState} from "react";
import {AuthContext} from "../context/auth-context";
import Snackbar from "@material-ui/core/Snackbar";

const LoginBox = styled(Box)({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  paddingTop: '10%'
})

const Login = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const goVehisService = useGoVehisService();
  const [wrongCredentials, setWrongCredentials] = useState(false);


  const submitLoginFormHandler = (values, {setSubmitting}) => {

    let credentials = new FormData();
    credentials.append("username", values.email);
    credentials.append("password", values.password);


    goVehisService.post("/admin/users/signin", credentials).then(response => {
      if (response.data) {
        setWrongCredentials(false);
        authContext.login(response.data);
        navigate('/app', {replace: true});
      }
    }).catch(error => {
      setWrongCredentials(true);
      authContext.logout();
      setSubmitting(false);
    })
  }

  return (
    <>
      <Helmet>
        <title>Logowanie | GO-auto - Panel administracyjny</title>
      </Helmet>
      <LoginBox>

        <Container maxWidth="sm">
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Logo/>
            <Typography variant="h3" style={{marginTop: 15, marginBottom: 5}}>Panel Administracyjny</Typography>
            <Typography color="textPrimary" variant="h5">Logowanie</Typography>

          </Box>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Proszę wprowadzić poprawny adres e-mail').max(255).required('Adres e-mail jest wymagana'),
              password: Yup.string().max(255).required('Hasło jest wymagane')
            })}
            onSubmit={submitLoginFormHandler}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>


                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Hasło"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box py={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Zaloguj się
                  </Button>
                </Box>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={wrongCredentials}
                  autoHideDuration={10000}
                  onClose={() => setWrongCredentials(false)}
                  message="Niepoprawny email lub hasło. Spróbuj ponownie."
                />

              </form>
            )}
          </Formik>
        </Container>
      </LoginBox>
    </>
  );
};

export default Login;
