import TabPanel from "../../common/TabPanel";
import theme from "../../../theme";
import FormSection from "../../common/FormSection";
import {Checkbox, FormControlLabel, Grid, ListItemText, TextField} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import {ErrorMessage} from "@hookform/error-message";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import RichTextEditor from "../../common/RichTextEditor";
import {convertToRaw} from "draft-js";
import React, {useContext} from "react";
import {SearchOptionsContext} from "../../../context/search-options-context";

const GeneralInformationTabPanel = ({data, tabValue, form, onRentalShow}) => {

  const searchOptions = useContext(SearchOptionsContext);

  const {control, formState: {errors}, watch, setValue} = form;
  const watchIsNewVehicle = watch("condition") === "NEW";
  const isRegisteredChecked = () => watch("isRegistered") === true;


  const salesDocumentKeys = () => {
    if (watchIsNewVehicle) {
      return Object.keys(searchOptions.salesDocument).filter(key => key === "FAKTURA_VAT");
    }

    return Object.keys(searchOptions.salesDocument);
  }

  return (
    <TabPanel value={tabValue} index={0} dir={theme.direction}>
      <FormSection label="Ustawienia oferty">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="isActive"
              control={control}
              defaultValue={data.isActive}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Czy oferta aktywna?"
                />

              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="calculationType"
              control={control}
              defaultValue={data.calculationType}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => {
                        setValue("calculationType", e.target.checked ? "RENTAL" : null);
                        onRentalShow(e.target.checked);
                      }
                      }
                      checked={data.isRental}
                    />
                  }
                  label="Czy oferta najmu?"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="isSpecial"
              control={control}
              defaultValue={data.isSpecial}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Czy oferta specjalna?"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="isSearchable"
              control={control}
              defaultValue={data.isSearchable}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Czy pokazać w wyszukiwaniu?"
                />
              )}
            />
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="isRegistered"
              control={control}
              defaultValue={data.isRegistered}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Czy zarejestrowany?"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {watchIsNewVehicle && <Controller
              name="hasReturnGuarantee"
              control={control}
              defaultValue={data.hasReturnGuarantee}
              render={({field}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  }
                  label="Czy z gwarancją zwrotu?"
                />
              )}
            />
            }
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="condition"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.condition}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Stan pojazdu"
                  fullWidth
                  variant="outlined"
                >
                  {Object.keys(searchOptions.condition).map((option) => (
                    <MenuItem key={option} value={option}>{searchOptions.condition[option]}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="condition">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="customerType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.customerType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Typ klienta"
                  fullWidth
                  variant="outlined"
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.map(customerType => searchOptions.customerTypeName[customerType]).join(', ')
                  }}
                >


                  {searchOptions.customerTypes.map((customerType) => (
                    <MenuItem key={customerType} value={customerType}>
                      <Checkbox checked={field.value.indexOf(customerType) > -1}/>
                      <ListItemText primary={searchOptions.customerTypeName[customerType]}/>
                    </MenuItem>
                  ))}

                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="customerType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="city"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                maxLength: {
                  value: 255,
                  message: <span className="error">Maksymalnie 255 znaków!</span>
                }
              }}
              defaultValue={data.city}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Miejscowość"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="city">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="availabilityType"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.availabilityType}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Dostępność pojazdu"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem
                    key={searchOptions.availabilities[0]}
                    value={searchOptions.availabilities[0]}
                  >
                    Od ręki
                  </MenuItem>
                  <MenuItem
                    key={searchOptions.availabilities[1]}
                    value={searchOptions.availabilities[1]}
                  >
                    Na zamówienie
                  </MenuItem>
                  <MenuItem
                    key={searchOptions.availabilities[2]}
                    value={searchOptions.availabilities[2]}
                  >
                    W produkcji
                  </MenuItem>
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="availabilityType">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          {!watchIsNewVehicle && <>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="mileage"
                control={control}
                shouldUnregister={true}
                rules={{
                  min: 0,
                  max: 1000000,
                  valueAsNumber: true
                }}
                defaultValue={data.mileage}
                render={({field}) => (
                  <TextField
                    {...field}
                    label="Przebieg [km]"
                    fullWidth
                    type="number"
                    variant="outlined"
                  />
                )}
              />
              <ErrorMessage errors={errors} name="mileage">
                {({messages}) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              </ErrorMessage>
            </Grid>
          </>}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="vin"
              control={control}
              rules={{
                maxLength: {
                  value: 17,
                  message: <span className="error">Numer VIN musi posiadać 17 znaków</span>
                }
              }}
              defaultValue={data.vin}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Numer VIN"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <ErrorMessage errors={errors} name="vin">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>

          {isRegisteredChecked() &&
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
              <Controller
                name="registeredDate"
                control={control}
                rules={{required: <span className="error">To pole jest wymagane!</span>}}
                defaultValue={data.registeredDate}
                shouldUnregister={true}
                render={({field}) => (
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableFuture
                    variant="outline"
                    inputVariant="outlined"
                    label="Data pierwszej rejestracji"
                    format="dd/MM/yyyy"
                    InputAdornmentProps={{position: "start"}}
                    invalidDateMessage={"Niepoprawny format daty"}
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage errors={errors} name="registeredDate">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>}

          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="supplierNip"
              control={control}
              defaultValue={data.supplierNip}
              render={({field}) => (
                <TextField
                  {...field}
                  label="NIP dostawcy"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>


        </Grid>
      </FormSection>
      <FormSection label="Dane finansowe">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="salesDocument"
              control={control}
              rules={{required: <span className="error">To pole jest wymagane!</span>}}
              defaultValue={data.salesDocument}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Dokument sprzedaży"
                  fullWidth
                  variant="outlined"
                >
                  {salesDocumentKeys().map((optionKey) => (
                    <MenuItem key={optionKey} value={optionKey}>{searchOptions.salesDocument[optionKey]}</MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage errors={errors} name="salesDocument">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="minimumSellPriceNet"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                pattern: {
                  value: /^[0-9.]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                },
                min: {
                  value: 1,
                  message: <span className="error">To pole jest wymagane!</span>
                }
              }}
              defaultValue={data.minimumSellPriceNet}
              render={({field}) => (
                <CurrencyTextField
                  {...field}
                  label="Nasza cena netto"
                  decimalCharacter=","
                  digitGroupSeparator=" "
                  onChange={(event, value) => field.onChange(value)}
                  fullWidth
                  variant="outlined"
                  currencySymbol="PLN"
                />
              )}
            />
            <ErrorMessage errors={errors} name="minimumSellPriceNet">
              {({messages}) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            </ErrorMessage>
          </Grid>
          <Grid item xs={12} sm={6}>
            {watchIsNewVehicle && <><Controller
              name="basicPriceNet"
              control={control}
              rules={{
                required: <span className="error">To pole jest wymagane</span>,
                pattern: {
                  value: /^[0-9.]+$/,
                  message: <span className="error">Dopuszczalne tylko liczby!</span>
                }
              }}
              shouldUnregister={true}
              defaultValue={data.basicPriceNet}
              render={({field}) => (
                <CurrencyTextField
                  {...field}
                  label="Cena katalogowa netto"
                  decimalCharacter=","
                  digitGroupSeparator=" "
                  onChange={(event, value) => field.onChange(value)}
                  fullWidth
                  variant="outlined"
                  currencySymbol="PLN"
                />
              )}
            />
              <ErrorMessage errors={errors} name="basicPriceNet">
                {({messages}) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              </ErrorMessage>
            </>
            }
          </Grid>
        </Grid>
      </FormSection>

      <FormSection label="Opis (opcjonalny)" style={{minHeight: '180px'}}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <RichTextEditor
              label="Możesz tu dodać opis oferty..."
              initialValue={data.description}
              onChange={value => {
                const content = JSON.stringify(
                  convertToRaw(value.getCurrentContent())
                );
                setValue("description", content);
              }}
              name="description"
            />


          </Grid>


        </Grid>
      </FormSection>

      <FormSection label="Opis wewnętrzny (niewidoczny dla użytkowników)" style={{minHeight: '180px'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="internalDescription"
              control={control}
              defaultValue={data.internalDescription}
              render={({field}) => (
                <TextField
                  {...field}
                  label=""
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  rowsMax={50}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>

        </Grid>
      </FormSection>

    </TabPanel>
  );
}

export default GeneralInformationTabPanel;
