import {useEffect, useState} from "react";
import {Spreadsheet} from "react-spreadsheet";
import GridCorner from "./GridCorner";

const RentalInstallmentGrid = ({company, installments, initialFeePercentage, customerType, onChange}) => {

  const EmptyCell = () => (
    <div style={{textAlign: "center", backgroundColor: "gray", color: "#ffffff"}}><strong>X</strong></div>
  );

  const NumbersEditor = ({cell, onChange}) => (
    <div className="Spreadsheet__data-editor">
      <input
        type="number"
        onChange={(e) => {
          onChange({...cell, value: e.target.value});
        }}
        value={cell.value}
        autoFocus
      />
    </div>
  );

  const matchInstallmentCriteria = (installment, contractLength, mileage) => {
    return installment.customerType === customerType && installment.initialFeePercentage == initialFeePercentage
      && installment.contractLength == contractLength && installment.vehicleMileage == mileage;
  }

  function getOverallMileage(contractLength, mileage) {
    return contractLength / 12 * mileage;
  }

  const mapInstallmentsDataToSpreadsheetFormat = (installmentsData) => {
    return company.availableContractLengths.map(contractLength => {
      return company.availableVehicleMileages.map(mileage => {
        const installment = installmentsData.find(installment => matchInstallmentCriteria(installment, contractLength, mileage))

        let cell = {value: installment ? installment.value.toString() : ""}
        if (getOverallMileage(contractLength, mileage) > company.vehicleMileageLimit) {
          cell = {value: null, DataViewer: EmptyCell, DataEditor: EmptyCell}
        } else{
          cell = {...cell, DataEditor: NumbersEditor}
        }

          return cell;
      })
    })
  }

  useEffect(() => {
    setData(mapInstallmentsDataToSpreadsheetFormat(installments))
  }, [company, installments])


  const [data, setData] = useState(mapInstallmentsDataToSpreadsheetFormat(installments));

  const dataChangeHandler = (changedData) => {
    // remove all data for customer type and initial fee
    let newInstallments = installments.filter(installment => installment.customerType !== customerType || installment.initialFeePercentage != initialFeePercentage);

    // push new data
    for (let contractLengthIndex = 0; contractLengthIndex < company.availableContractLengths.length; contractLengthIndex++) {
      for (let mileageIndex = 0; mileageIndex < company.availableVehicleMileages.length; mileageIndex++) {
        const installment = changedData[contractLengthIndex][mileageIndex];

        if (installment && installment.value !== null && installment.value !== "") {
          newInstallments.push({
            customerType: customerType,
            initialFeePercentage: initialFeePercentage,
            contractLength: company.availableContractLengths[contractLengthIndex],
            vehicleMileage: company.availableVehicleMileages[mileageIndex],
            value: parseFloat(installment.value.replace(/\,/g, '.'))
          });
        }
      }
    }


    onChange(newInstallments);

  }


  return (
    <Spreadsheet
      data={data}
      onChange={dataChangeHandler}
      rowLabels={company.availableContractLengths}
      columnLabels={company.availableVehicleMileages}
      CornerIndicator={GridCorner}
    />
  );
}

export default RentalInstallmentGrid;
