import {Helmet} from 'react-helmet';
import {Box, Container} from '@material-ui/core';
import React, {useEffect, useState} from "react";
import {useGoVehisService} from "../utils/axios";
import {SearchOptionsContext} from "../context/search-options-context";
import VehicleOfferTable from "../components/offer/table/VehicleOfferTable";
import VehicleOffersListToolbar from "../components/offer/VehicleOffersListToolbar";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

function OfferList() {
  const goVehisService = useGoVehisService();
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = React.useState('');
  const [realoadOfferList, setReloadOfferList] = React.useState(false);
  const [selectOptions, setSelectOptions] = useState({
    brandsAndModels: [],
    vehicleTypes: [
      "OSOBOWE",
      "DOSTAWCZE"
    ],
    customerTypes: [
      "COMPANY",
      "PERSON"
    ],
    gearboxTypes: [
      "Automatyczna",
      "Manualna"
    ],
    fuelTypes: [
      "Benzyna",
      "Benzyna + LPG",
      "Diesel",
      "Elektryczny",
      "Hybryda"
    ],
    driveTypes: [
      "Na wszystkie koła",
      "Napęd przedni",
      "Napęd tylny"
    ],
    availabilities: [
      "IN_STOCK",
      "TO_ORDER",
      "IN_PRODUCTION"
    ],
    specialOffers: {
      isTrue: "true",
      isFalse: "false"
    },
    isActive: {
      isTrue: "true",
      isFalse: "false"
    },
    numbersOfDoors: [
      3,
      4,
      5
    ],
    numbersOfSeats: [
      3,
      4,
      5,
      7
    ],
    condition: {
      "NEW": "Nowy",
      "USED": "Używany",
      "DEMO": "Demo"
    },
    customerTypeName: {
      "COMPANY": "Firma",
      "PERSON": "Osoba prywatna"
    },
    salesDocument: {
      "FAKTURA_VAT": "Faktura VAT",
      "VAT_MARZA": "Faktura VAT marża",
      "UMOWA_KS": "Umowa K-S",
      "VAT_ZW": "Faktura VAT ZW"
    },
    offerState: {
      "AVAILABLE": "Dostępny",
      "RESERVED": "Zarezerwowany",
      "SOLD": "Sprzedany"
    },
    calculationTypeName: {
      "LEASING": "Leasing",
      "CREDIT": "Kredyt",
      "RENTAL": "Wynajem"
    }
  });

  useEffect(() => {
    goVehisService.get('/admin/selectoptions')
      .then(response => {
        const newOfferOptions = response.data;

        const result = {...selectOptions};
        result.brandAndModels = newOfferOptions.brandsAndModels;
        setSelectOptions(result);
      })
  }, [])


  return (
    <SearchOptionsContext.Provider value={selectOptions}>
      <Helmet>
        <title>Oferty | GO-auto - Panel administracyjny</title>
      </Helmet>
      <Box
        style={{
          minHeight: '100%'
        }}
      >
        <Container maxWidth={false}>
          <Box pt={1}>
            <VehicleOffersListToolbar
              onSearch={setSearchQuery}
              onNewOfferAdded={() => setReloadOfferList(true)}
            />
            <Paper className={classes.paper}>
              <VehicleOfferTable searchQuery={searchQuery} reload={realoadOfferList}
                                 onReloaded={() => setReloadOfferList(false)}/>
            </Paper>
          </Box>
        </Container>
      </Box>
    </SearchOptionsContext.Provider>
  );
}

export default OfferList;
