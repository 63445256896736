import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";

const headCells = [
  {id: 'is_active', numeric: false, disablePadding: false, label: null},
  {id: 'id', numeric: true, disablePadding: true, label: 'ID'},
  {id: 'brand_name', numeric: false, disablePadding: false, label: 'Marka'},
  {id: 'version_name', numeric: false, disablePadding: false, label: 'Wersja'},
  {id: 'condition', numeric: false, disablePadding: false, label: 'Stan'},
  {id: 'body_type', numeric: false, disablePadding: false, label: 'Nadwozie'},
  {id: 'minimum_sell_price_net', numeric: false, disablePadding: false, label: 'Cena sprzedaży netto'},
  {id: 'calculation_type', numeric: false, disablePadding: true, label: 'Forma finansowania'},
  {id: 'owner_code', numeric: false, disablePadding: false, label: 'Opiekun'},
  {id: 'state', numeric: false, disablePadding: false, label: 'Status'},
  {id: 'created_at', numeric: false, disablePadding: false, label: 'Data dodania'},
  {id: 'actions', numeric: false, disablePadding: false, label: 'Akcje'},
];

function canSort(columnId){
  return !['actions'].includes(columnId);
}

const TableHeader = (props) => {
  const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    if(canSort(property)) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all offers'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id &&  canSort(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!canSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TableHeader;
