import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AppBar, DialogTitle, Tab, Tabs, useMediaQuery, useTheme} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import VehicleOfferForm from "./form/VehicleOfferForm";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 999999999,
    color: '#fff',
  },

}));

const VehicleOfferDialog = ({data, isOpen, onClose, onSubmit, title}) => {


  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [showRental, setShowRental] = useState(data.calculationType === "RENTAL");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const submitHandler = (data) => {

    setBackdropOpen(true);
    return onSubmit(data).finally(() => {
      onClose();
      setBackdropOpen(false);
    });
  }


  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        scroll="body"
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title" disableTypography={true}><Typography variant="h3" gutterBottom
                                                                                  style={{borderBottom: 'solid 1px'}}>
          {title}
        </Typography></DialogTitle>
        <DialogContent>
          <Backdrop className={classes.backdrop} open={backdropOpen} onClick={() => setBackdropOpen(false)}>
            <CircularProgress color="inherit"/>
          </Backdrop>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Dane ogólne oferty" value={0}/>
                {showRental && <Tab label="Oferta wynajmu" value={1}/>}
                <Tab label="Dane techniczne pojazdu" value={2}/>
                <Tab label="Wyposażenie" value={3}/>
                <Tab label="Zdjęcia" value={4}/>
              </Tabs>
            </AppBar>
            <VehicleOfferForm
              data={data}
              tabValue={tabValue}
              onSubmit={submitHandler}
              onCancel={onClose}
              onRentalShow={(show) => setShowRental(show)}
            />

          </div>
        </DialogContent>
      </Dialog>
    </>
  );

}

export default VehicleOfferDialog;
