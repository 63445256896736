
 class FormatUtils{

     /**
      * Formats number to polish currency value
      *
      * @param number value to format
      * @param decimalLength length of decimal fraction
      */
     static formatMoney(value, decimalLength = 0) {
         let sectionsDelimiter = " ";
         let decimalDelimiter = ",";
         let regExp = '\\d(?=(\\d{3})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
         //value = value.toFixed(decimalLength);
         value = Math.ceil(value).toFixed(0);

         return (decimalDelimiter ? value.replace('.', decimalDelimiter) : value)
             .replace(new RegExp(regExp, 'g'), '$&' + sectionsDelimiter)
             + " zł";
     };

     static formatPhone(phoneNumber){
         let sections = phoneNumber.match(/.{1,3}/g);

         return sections.join(" ");
     }
 }

 export default FormatUtils;