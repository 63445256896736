import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  cell: {
    background: "linear-gradient(to right top, #ffffff 0%,#ffffff 49.9%,#E7E7E7 50%,#E7E7E7 51%,#ffffff 51.1%,#ffffff 100%)",
    fontSize:"10px",
    color: "#172b4d",
    padding: "1px"
  },
  topRightLabel: {
    textAlign: "right"
  },
  downLeftLabel: {
    textAlign: "left"
  },
});

const GridCorner = () => {
  const classes = useStyles();

  return (
    <th className={"Spreadsheet__header " + classes.cell}>
      <div className={classes.topRightLabel}>roczny przebieg <br/>[km]</div>
      <div className={classes.downLeftLabel}>okres<br/> umowy [mc]</div>
    </th>
  );
}

export default GridCorner;
