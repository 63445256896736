import {Card, CardContent, Typography} from "@material-ui/core";
import React from "react";

const FormSection = ({children, label, style}) =>
  <Card variant="outlined" style={{...style, marginBottom: 15}}>
    <CardContent>
      <Typography variant="h5" gutterBottom style={{marginBottom: 10}}>{label}</Typography>
      {children}
    </CardContent>
  </Card>

export default FormSection;
