import axios from 'axios';
import {AuthContext} from "../context/auth-context";
import {useContext} from "react";


export const useGoVehisService = () => {
  const authContext = useContext(AuthContext);

  const service = axios.create({
    baseURL: process.env.REACT_APP_GO_VEHIS_API_URL
  });

  //request interceptor to add the auth token header to requests
  service.interceptors.request.use(
    (config) => {
      if (authContext.user.token) {
        config.headers["Authorization"] = "Bearer " + authContext.user.token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  service.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 403) {
      authContext.logout();
    }
    return Promise.reject(error);
  });

  return service;
}



