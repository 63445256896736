import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, ListItem} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: theme.spacing(0),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textTransform: 'none',
    width: '100%',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  listItem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  }
}));

const NavItem = ({
                   href,
                   icon: Icon,
                   title,
                   ...rest
                 }) => {

  const classes = useStyles();
  const location = useLocation();

  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  return (
    <ListItem
      disableGutters
      className={classes.listItem}
      {...rest}
    >
      <Button
        component={RouterLink}
        className={classes.button}
        style={{...(active && {
            color: 'primary.main'
          })
        }}
        to={href}
      >
        {Icon && (
          <Icon size="20"/>
        )}
        <span>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
