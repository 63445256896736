import {useContext, useEffect} from "react";
import {AuthContext} from "../../context/auth-context";
import {useNavigate} from "react-router";

const Logout = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.logout();

    navigate("/login", { replace: true });
  },[])


  return null;
}

export default Logout;
