import React, {useState} from "react";
import {Box, Button, Card, CardContent, InputAdornment, SvgIcon, TextField} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import {Search as SearchIcon} from 'react-feather';
import AddVehicleOffer from "./AddVehicleOffer";

function VehicleOffersListToolbar({onSearch, onNewOfferAdded}) {
  const [query, _setQuery] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (e) => {
    _setQuery(e.target.value);
  }

  const processSearchHandler = () => {
    onSearch(query);
    setOpen(true);
    setTimeout(() => {
      setOpen(false)
    }, 4000);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      processSearchHandler();
    }
  }

  const searchButtonClickedHandler = () => {
    processSearchHandler();
  }

  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={1000}
        message="Wykonano wyszukiwanie"
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {/*<Button>*/}
        {/*  Import*/}
        {/*</Button>*/}

        <AddVehicleOffer onSaved={onNewOfferAdded}/>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box style={{maxWidth: 500}}>
              <TextField
                fullWidth
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        onClick={searchButtonClickedHandler}
                      >
                        <SearchIcon/>
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Wyszukaj ofertę po ID, marce, wersji lub VIN"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default VehicleOffersListToolbar;
