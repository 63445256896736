import MUIRichTextEditor from "mui-rte";
import React from "react";
import {ContentState, convertFromHTML, convertToRaw} from "draft-js";

const RichTextEditor = (props) => {

  let defaultValue = "";



  if(props.initialValue){
    const contentHTML = convertFromHTML(props.initialValue)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    defaultValue = JSON.stringify(convertToRaw(state))
  }

  return (
    <MUIRichTextEditor
      {...props}
      defaultValue={defaultValue}
      readOnly={false}
      lineToolbar={true}
      controls={['bold', 'italic','underline','link','numberList','bulletList']}
    />
  );

}

export default RichTextEditor;
