import TabPanel from "../../common/TabPanel";
import theme from "../../../theme";
import FormSection from "../../common/FormSection";
import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@material-ui/core";
import {Controller} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useGoVehisService} from "../../../utils/axios";
import MenuItem from "@material-ui/core/MenuItem";
import RentalInstallments from "./RentalOffer/RentalInstallments";

const RentalOfferTabPanel = ({data, tabValue, form}) => {

  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(data.rentalOffer.company);
  const goVehisService = useGoVehisService();

  const {control, setValue, getValues} = form;

  useEffect(() => {
    goVehisService.get("/admin/rental-companies").then( response => setAvailableCompanies(response.data));
    setValue("rentalOffer.selectedOptions", data.rentalOffer.selectedOptions);
    setValue("rentalOffer.installments", data.rentalOffer.installments);
  },[]);

  const rentalCompanyChangeHandler = companyCode => {
    const company = availableCompanies.find(c => c.code === companyCode);

    let selectedOptions = [];

    company.offerOptions.forEach(option => {
      if(option.isDefault){
        selectedOptions.push(option);
      }
    })

    setValue("rentalOffer.selectedOptions", selectedOptions);
    setValue("rentalOffer.company", company);
    setSelectedCompany(company);
  }

  const rentalOptionChangeHandler = (event, option) => {
    const {checked} = event.target;
    let newSelectedOptions = [...getValues("rentalOffer.selectedOptions")];

    if(checked){
      newSelectedOptions.push(option)
    } else {
      newSelectedOptions = newSelectedOptions.filter( element => element.code != option.code);
    }

    setValue("rentalOffer.selectedOptions", newSelectedOptions, { shouldValidate: true });
  }

  let rentalOptionsCheckboxes = null;

  if(selectedCompany && selectedCompany.offerOptions && getValues("rentalOffer.selectedOptions")){
    rentalOptionsCheckboxes = selectedCompany.offerOptions.map( option => (

            <FormControlLabel
              key={option.code}
              control={
                <Checkbox
                  value={option.code}
                  color="primary"
                  onChange={ e => rentalOptionChangeHandler(e, option)}
                  checked={getValues("rentalOffer.selectedOptions").some(element => element.code === option.code)}
                />
              }
              label={option.name}
            />
      ))
  }

  return (
    <TabPanel value={tabValue} index={1} dir={theme.direction}>
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="rentalOffer.company.code"
              control={control}
              defaultValue={selectedCompany.code}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  label="Firma finansująca"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => {
                    rentalCompanyChangeHandler(event.target.value)
                    field.onChange(event.target.value);
                  }}
                >
                  {availableCompanies.map((company) => (
                    <MenuItem
                      key={company.code}
                      value={company.code}
                      selected={company.code === selectedCompany.code}
                    >
                      {company.name}</MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Usługi w ramach finansowania</Typography>
            {rentalOptionsCheckboxes}
          </Grid>
        </Grid>
      </FormSection>
      {selectedCompany.code !== "" && <FormSection label="Matryce rat wynajmu">
        <Grid container spacing={3}>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <RentalInstallments
              company={selectedCompany}
              installments={getValues("rentalOffer.installments") ? getValues("rentalOffer.installments") : data.rentalOffer.installments}
              customerTypes={getValues("customerType")}
              onChange={installments => setValue("rentalOffer.installments", installments, { shouldValidate: true })}
            />
          </Grid>
        </Grid>
      </FormSection>}


    </TabPanel>
  );
}

export default RentalOfferTabPanel;
