import {useContext, useEffect, useState} from "react";
import {CircularProgress, Link} from "@material-ui/core";
import {useGoVehisService} from "../../../utils/axios";
import OwnerInfoDialog from "./OwnerInfoDialog";
import {AuthContext} from "../../../context/auth-context";

const OwnerName = ({code}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [owner, setOwner] = useState(true);

  const authContext = useContext(AuthContext);

  const goVehisService = useGoVehisService();

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  useEffect(() => {
    // omit fetching seller for default code
    if (code) {
      goVehisService.get('/sellers/' + code)
        .then(response => {
          let sellerData = {...response.data};

          if(sellerData) {
            setOwner(sellerData);
            setIsLoading(false)
          }
        })
        .catch(error => console.log("Cannot get seller"));
    }
  }, [code]);

  const ownerName = () => {
    if(authContext.user.code === owner.code && owner.type === 'AGENT'){
      return " (" + owner.agentFirstName + " " + owner.agentLastName + ")";
    }
  }

  return isLoading ? <CircularProgress /> :
    <>
      <Link href="#" onClick={handleDialogOpen}>{owner.firstName + " " + owner.lastName}</Link> {ownerName()}
      <OwnerInfoDialog isOpen={isDialogOpen} owner={owner} onClose={handleDialogClose}/>
    </>;

}

export default OwnerName;
