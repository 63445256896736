import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';


const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {

  return (
    <AppBar
      elevation={2}
      {...rest}
    >
      <Toolbar style={{backgroundColor: '#ffffff'}}>
        <RouterLink to="/">
          <Logo/>
        </RouterLink>
        <Hidden smDown>
          <Typography variant="h3" style={{marginLeft: 15, color: '#176fb7'}}>Panel Administracyjny</Typography>
        </Hidden>
        <Box style={{flexGrow: 1}}/>

        <IconButton href="/logout">
          <InputIcon/>
        </IconButton>
        <IconButton onClick={onMobileNavOpen}>
          <MenuIcon/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
