import {Box, Typography} from "@material-ui/core";
import React from "react";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography component="span">{children}</Typography>
      </Box>
    </div>
  );
}

export default TabPanel;
