const Logo = (props) => (
  <img
    height="30"
    alt="GO-auto logo"
    src="/static/logo.svg"
    {...props}
  />
);

export default Logo;
