import theme from "../../../theme";
import FormSection from "../../common/FormSection";
import {Grid, TextField} from "@material-ui/core";
import {Controller} from "react-hook-form";
import Attachments from "./Attachments";
import TabPanel from "../../common/TabPanel";
import React from "react";

const SpecificationTabPanel = ({data, tabValue, form, attachments, onSetAttachments}) => {

  const {control} = form;

  return (
    <TabPanel value={tabValue} index={3} dir={theme.direction}>
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="standardEquipment"
              control={control}
              defaultValue={data.standardEquipment}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Wyposażenie standardowe (po przecinku)"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  rowsMax={50}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="additionalEquipment"
              control={control}
              defaultValue={data.additionalEquipment}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Wyposażenie dodatkowe (po przecinku)"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  rowsMax={50}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Załączniki">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Attachments
              attachments={attachments}
              onChange={(attachments) => onSetAttachments(attachments)}
              vehicleId={data.vehicleId}
            />
          </Grid>

        </Grid>
      </FormSection>
    </TabPanel>
  );
}

export default SpecificationTabPanel;
