import React, {useEffect, useState} from "react";

export const USER_KEY = "userData";

export const ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER"
}

const defaultUser = {
  id: 0,
  code: "O1",
  username: "auto@go-leasing.pl",
  firstName: "",
  lastName: "",
  role: ROLES.ROLE_USER,
  token: null
}

export const AuthContext = React.createContext({
  isAuthenticated: false,
  user: defaultUser,
  login: () => {
  },
  logout: () => {
  },
});


const AuthContextProvider = props => {
  const readUser = () => {
    const userValue = sessionStorage.getItem(USER_KEY);
    if (userValue) {
      return JSON.parse(userValue);
    }

    return defaultUser
  }

  const [isAuthenticated, setIsAuthenticated] = useState(readUser().token ? true : false);
  const [user, setUser] = useState(readUser());

  useEffect(() => {
    const userValue = sessionStorage.getItem(USER_KEY);
    if (userValue) {
      setIsAuthenticated(true);
      setUser(JSON.parse(userValue));
    }
  }, []);

  const loginHandler = user => {

    if (user) {
      setIsAuthenticated(true);
      setUser(user);
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

  }

  const logoutHandler = () => {
    setIsAuthenticated(false);
    setUser(defaultUser);
    sessionStorage.removeItem(USER_KEY);

  }

  return (
    <AuthContext.Provider
      value={{isAuthenticated: isAuthenticated, user: user, login: loginHandler, logout: logoutHandler}}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
