import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate} from "react-router";
import {styled, withStyles, makeStyles} from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import theme from "../theme";
import {AuthContext} from "../context/auth-context";

const DashboardLayoutRoot = styled('div')({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
})

const DashboardLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 75
})

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
})

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
})

const DashboardLayout = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.isAuthenticated === false) {
      window.location.href = '/login';
    }
  })

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      {
        authContext.isAuthenticated &&
        <DashboardLayoutRoot>
          <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)}/>
          <DashboardSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <Outlet/>
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      }
    </>
  );
};

export default DashboardLayout;
